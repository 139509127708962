.cardHamInclude {
  display: flex;
  padding-block: 2rem 0.5rem;
  align-content: center;
  justify-content: center;
}
.headerPara {
  font-size: clamp(1.6rem, 1.4vw, 3rem);
  width: 100%;
  padding-block: 1rem;
  text-align: center;
  text-decoration: underline 1px solid var(--lunchSpan);
}
.header {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}
.imageContainer {
  position: relative;
  width: 200px;
  height: 100px;
  overflow: hidden;
  border-radius: 8px;
}

.loadingSkeleton {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@keyframes pulse {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.loadingSkeleton {
  animation: pulse 1.5s infinite linear;
  background: linear-gradient(90deg, #eeeeee, #cfcfcf, #eeeeee);
  background-size: 400% 400%;
  border-radius: 8px;
}
.box {
  display: flex;
  left: 5px;
  z-index: 9;
  font-size: 16px;
  transition: 0.2s;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}

.box:hover input {
  border-radius: 10px;
}
.box:hover input {
  border-radius: 10px;
}
.box:hover i {
  opacity: 0;
  z-index: -1;
}

.input {
  padding: 5px;
  max-width: 300px;
  height: 60px;
  background: none;
  border: 4px solid var(--black-color);
  border-radius: 12px;
  box-sizing: border-box;
  font-size: 26px;
  color: var(--black-color);
  opacity: 0.8;
  outline: none;
  transition: 0.5s;
  text-indent: 1rem;
  margin-block: 1rem;
}
.imageWrapper {
  position: relative;
}

.comingSoon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--background);
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
}
.pizzaGroupHeader {
  display: flex;
  width: 100%;
  align-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}
.pizzaSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 19rem;
  justify-content: center;
}
.pizzaTitle {
  color: var(--lunchSpan);
  font-size: clamp(3rem, 1.4vw, 4.5rem);
  padding-block: 2rem 1rem;
}
.wrapper {
  margin-top: 19rem;
}
.wrapper .cardContainer {
  margin-top: 0;
}
.cardItemWrapper {
  display: flex;
  width: 100%;
  padding-block: 2rem 1rem;
  justify-content: space-between;
}
.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 20px; */
  width: 100%;
}

.card {
  background-color: var(--background);
  border-radius: 10px;
  box-shadow: var(--boxShadow);
  max-width: var(--max-width);
  width: 100%;
}

.cardHeader {
  text-align: center;
  padding: 20px;
}
.cardHeader h2 {
  margin-bottom: 2rem;
  color: var(--lunchSpan);
  font-size: clamp(2.4rem, 1.4vw, 3.6rem);
  font-weight: 800;
}

.additional {
  display: flex;
  width: 100%;
  font-size: clamp(1.6rem, 1.4vw, 2.4rem);
  justify-content: space-around;
}
.additionalPann {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 10px 0 20px;
}

.cardsContainer {
  display: grid;
  gap: 20px;
  padding: 20px;
}
.grillIng {
  font-weight: 700;
  width: 100%;
  display: flex;
  padding-block: 1rem 0.5rem;
  align-items: center;
  justify-content: flex-start;
}
.cardItem {
  display: flex;
  border: 1px solid var(--text-color);
  border-radius: 8px;
  padding: 15px;
  box-shadow: var(--boxShadow);
  width: 100%;
  flex-direction: column;
  flex-direction: column;
  justify-content: space-between;
}

.imageContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
}

.imageUrl {
  object-fit: cover;
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  border-radius: 8px;
}
.figCaption {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  text-align: center;
  font-size: clamp(1.6rem, 1.4vw, 2.5rem);
  font-weight: 700;
  align-items: center;
}
.div {
  background-color: green;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-around;
}

.cardSpan,
.cardSpanIng {
  padding-block: 1rem;
  font-weight: 400;
  font-size: clamp(1.6rem, 1.4vw, 1.8rem);
}

@media screen and (min-width: 768px) {
  .cardsContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .cardsContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}
